<template>
	<nav class="nav navbar-expand-lg navbar-dark bg-dark" v-show="!isMetaverseMobile">
		<router-link class="navbar-brand" to="/">
			<img src="assets/alpha_logo_new.png" :height="isMobile ? '30px' : '44px'" class="d-inline-block align-top" alt="Hourglass">
		</router-link>
		<button class="navbar-toggler py-0 fs-6" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav me-auto">
				<li class="nav-item mx-2 active">
					<router-link to="/" class="nav-link" href="#"><i class="fa fa-home me-2"></i> Home <span class="sr-only">(current)</span></router-link>
				</li>
				<!--					<li class="nav-item">-->
				<!--						<router-link to="/profile/referrals" class="nav-link">Referrals</router-link>-->
				<!--					</li>-->

				<li class="nav-item mx-2">
					<router-link to="/city" class="nav-link"><i class="fa fa-city me-2"></i> City <sup class="small text-primary">NEW!</sup></router-link>
				</li>

				<!--				<li class="nav-item mx-2">-->
				<!--					<router-link to="/profile/land" class="nav-link"><i class="fa fa-map me-2"></i> Land</router-link>-->
				<!--				</li>-->
				<li class="nav-item mx-2">
					<router-link to="/map" class="nav-link"><i class="fa fa-globe me-2"></i> World Map</router-link>
				</li>
				<li class="nav-item mx-2">
					<!--					<a target="_blank" href="https://explorer.hourglassmeta.com" class="nav-link"><i class="fa fa-search me-2"></i> Explorer</a>-->
					<router-link to="/metaverse" class="nav-link"><i class="fa fa-search me-2"></i> Explorer</router-link>
				</li>
			</ul>
			<ul class="navbar-nav me-auto">
				<li class="nav-item mx-2">
					<a target="_blank" href="https://magiceden.io/marketplace/alphabatem" class="nav-link"><i class="fa fa-shop me-2"></i> Marketplace</a>
				</li>
				<li class="nav-item mx-2" v-show="!isMobile">
					<router-link to="/demo/editor" class="nav-link"><i class="fa fa-hammer me-2"></i> Room Builder
						<sup class="small text-primary">NEW!</sup></router-link>
				</li>
			</ul>
			<ul class="navbar-nav ms-auto" v-if="isConnected">
				<li class="nav-item mx-2">
					<ReportBug class="nav-item white nolink"></ReportBug>
				</li>
				<li class="nav-item mx-2">
					<router-link to="/profile/dashboard" class="nav-item white nolink" href="#"><i class="fa fa-user me-2"></i> Profile</router-link>
				</li>
			</ul>

			<div class="auth d-sm-block pb-2">
				<router-link to="/login" class="btn btn-primary me-3 px-4" v-if="!isConnected">Login</router-link>
			</div>
		</div>
	</nav>
</template>

<script>
import Phantom from "./components/wallet/Phantom";
import ReportBug from "./components/common/ReportBug";
import MobileDetector from "./js/util/mobileDetector";

export default {
	name: "Navbar",
	components: {ReportBug, Phantom},
	data() {
		return {
			showCreate: false,
			date: new Date().toLocaleDateString("en-GB"),
			time: new Date().toLocaleTimeString("en-GB"),
			clockInterval: null
		}
	},
	computed: {
		isConnected: function () {
			return this.$store.state.wallet.connected
		},
		isMobile: function() {
			return this.$store.state.settings.isMobile
		},
		isMetaverseMobile: function() {
			if (!this.isMobile)
				return false

			return this.$route.meta.isGameView
		},
	},
	methods: {
		onWalletConnected() {
			this.showCreate = true;
		},
		toggleDropdown(e) {
			document.getElementById('navbarSupportedContent').parentElement.classList.add("collapsed")
			document.getElementById('navbarSupportedContent').classList.remove("show")
		}
	},
	beforeDestroy() {
		clearInterval(this.clockInterval)
	},
	mounted() {
		this.clockInterval = setInterval(() => {
			this.time = new Date().toLocaleTimeString()
		}, 1000)
	},
	updated() {
		console.log("Updated")
		this.toggleDropdown()
	}
}
</script>

<style scoped>
nav {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
}

.navbar-brand, .navbar-dark .navbar-nav .nav-link, .nav-item {
	color: white;
}

.nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 15px;
	z-index: 9999;
}

.nav-item:hover * {
	transform: scale(1.05);
	transition: all 0.1s ease-in;
}

.nav-item > a {
	border-bottom: 2px solid rgba(255, 255, 255, 0);
	transition: all 300ms;
}

.nav-item:hover > a {
	text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
	border-bottom: 2px solid rgba(255, 255, 255, 1);
}

</style>
