import MobileDetector from "../js/util/mobileDetector";

export default {
	namespaced: true,
	state: () => ({
		isMobile: false,
		rpcConnection: {
			url: "https://solana-mainnet.g.alchemy.com/v2/k4wb7PGZkC8_7ZtJNQYhn3tEoPfq6dyE",
			commitment: "confirmed",
			token: "",
		}
	}),
	mutations: {
		set_is_mobile(state, isMobile) {
			state.isMobile = isMobile;
		},
	},
	getters: {
		getConnectionParams(state) {
			if (state.rpcConnection.token === '')
				return state.rpcConnection.commitment

			return {
				commitment: state.rpcConnection.commitment,
				httpHeaders: {
					"Authorization": `Bearer ${state.rpcConnection.token}`,
					"Content-Type": "application/json"
				}
			}
		}
	},
	actions: {
		async getIsMobile({commit}) {
			commit('set_is_mobile', MobileDetector.isMobile())
		},
	}
}
