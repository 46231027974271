var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.$store.state.settings.isMobile ? "mobile" : "",
      attrs: { id: "app" },
    },
    [
      _c("Navbar"),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("div", { class: `loader ${_vm.loading ? "loading" : ""}` }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }