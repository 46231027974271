<template>
  <div id="app" :class="$store.state.settings.isMobile ? 'mobile' : ''">
		<Navbar></Navbar>
    <router-view/>
		<div :class="`loader ${loading ? 'loading' : ''}`"></div>
  </div>
</template>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
</style>
<script>
import Navbar from "./Navbar";
import { mapActions } from "vuex"
import MobileDetector from "./js/util/mobileDetector";
import {UserAssets} from "./js/util/user_assets";

export default {
	components: {Navbar},
	data() {
		return {
			loading: false,
		}
	},
	'$store.state.wallet.address'() {
		console.log("App::WalletAddr Changed")
		this.getProfile(this.$store.state.wallet.address);
	},
	methods: {
		...mapActions({
			getProfile: 'profile/getProfile',
		}),
	},
	beforeMount() {
		this.$store.commit('settings/set_is_mobile',MobileDetector.isMobile())
		this.$store.commit('profile/set_user_assets', new UserAssets(this.$store.state.settings.rpcConnection.url, this.$store.getters['settings/getConnectionParams']));

		const token = localStorage.getItem("jwt_token")
		if (token !== null) {
			this.$store.commit('wallet/set_jwt_token', token);
			this.$store.commit('wallet/set_wallet_connected', true);
		}

		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (params.token) {
			this.$store.commit('wallet/set_jwt_token', params.token);
			this.$store.commit('wallet/set_wallet_connected', true);
		}

		this.$router.beforeEach((to, from, next) => {
			this.loading = true
			setTimeout(() => {next()}, 310)
		})

		this.$router.afterEach((to, from) => {
			setTimeout(() => {this.loading = false}, 100)
		})
	},
	mounted() {
		if (this.$store.state.wallet.connected)
			this.getProfile(this.$store.state.wallet.address).catch(e => {})
	},
}
</script>
