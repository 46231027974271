import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		// component: () => import(/* webpackChunkName: "index" */ '../views/Home.vue')
		component: () => import(/* webpackChunkName: "index" */ '../views/metaverse/MetaverseIndex')
	},
	{
		path: '/signup',
		name: 'Signup',
		component: () => import(/* webpackChunkName: "login" */ '../views/Signup.vue')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
	},
	{
		path: '/city',
		name: 'City',
		component: () => import(/* webpackChunkName: "city" */ '../views/metaverse/MetaverseCity.vue'),
		meta: {
			isGameView: true
		}
	},
	{
		path: '/web2/signup',
		name: 'Signup Web2',
		component: () => import(/* webpackChunkName: "login_web2" */ '../views/web2/Signup.vue')
	},
	{
		path: '/web2/login',
		name: 'Login Web2',
		component: () => import(/* webpackChunkName: "login_web2" */ '../views/web2/LoginEmail.vue')
	},
	{
		path: '/qr/tp',
		name: 'QR Teleport',
		component: () => import(/* webpackChunkName: "login_qr" */ '../views/qr/TeleportQR.vue')
	},
	{
		path: '/qr/signup',
		name: 'Signup QR',
		component: () => import(/* webpackChunkName: "login_qr" */ '../views/qr/SignupQR.vue')
	},
	{
		path: '/qr/login',
		name: 'Login QR',
		component: () => import(/* webpackChunkName: "login_qr" */ '../views/qr/LoginQR.vue')
	},
	{
		path: '/qr/create',
		name: 'Create QR',
		component: () => import(/* webpackChunkName: "qr_code" */ '../views/qr/CreateQR.vue')
	},
	{
		path: '/qr/:id',
		name: 'Show QR',
		component: () => import(/* webpackChunkName: "qr_code" */ '../views/qr/ShowQR.vue')
	},
	{
		path: '/metaverse',
		name: 'Metaverse',
		component: () => import(/* webpackChunkName: "index" */ '../views/metaverse/MetaverseIndex.vue')
	},
	{
		path: '/demo/editor',
		name: 'Metaverse Demo Editor',
		component: () => import(/* webpackChunkName: "demo" */ '../views/metaverse/MetaverseDemo.vue'),
		meta: {
			isGameView: true
		}
	},
	{
		path: '/demo/visit/:id',
		name: 'Metaverse Demo',
		component: () => import(/* webpackChunkName: "demo" */ '../views/metaverse/MetaverseDemoShow.vue'),
		meta: {
			isGameView: true
		}
	},
	{
		path: '/land/:id/create',
		name: 'Metaverse Wizard',
		component: () => import(/* webpackChunkName: "manifest" */ '../views/metaverse/MetaverseCreate')
	},
	{
		path: '/metaverse/:id',
		name: 'Metaverse Show',
		component: () => import(/* webpackChunkName: "metaverse" */ '../views/metaverse/MetaverseShowDetail.vue')
	},
	{
		path: '/metaverse/:id/visit',
		name: 'Metaverse Visit',
		component: () => import(/* webpackChunkName: "metaverse" */ '../views/metaverse/MetaverseShow.vue'),
		meta: {
			isGameView: true
		}
	},
	{
		path: '/metaverse/:id/edit',
		name: 'Metaverse Edit',
		component: () => import(/* webpackChunkName: "metaverse" */ '../views/metaverse/MetaverseEdit.vue'),
		meta: {
			isGameView: true
		}
	},
	{
		path: '/metaverse/:id/manage',
		name: 'Metaverse Dashboard',
		component: () => import(/* webpackChunkName: "manager" */ '../views/manager/MetaverseManagerDashboard.vue')
	},
	{
		path: '/metaverse/:id/manage/chat',
		name: 'Chat Log',
		component: () => import(/* webpackChunkName: "manager" */ '../views/manager/ChatLogIndex')
	},
	{
		path: '/metaverse/:id/manage/players',
		name: 'Players',
		component: () => import(/* webpackChunkName: "manager" */ '../views/manager/PlayerIndex')
	},
	{
		path: '/metaverse/:id/manage/configuration',
		name: 'Configuration',
		component: () => import(/* webpackChunkName: "manager" */ '../views/manager/ConfigurationIndex')
	},
	{
		path: '/metaverse/:id/manage/moderation',
		name: 'Moderation',
		component: () => import(/* webpackChunkName: "manager" */ '../views/manager/ModerationIndex')
	},
	{
		path: '/metaverse/:id/manage/monetisation',
		name: 'Monetisation',
		component: () => import(/* webpackChunkName: "manager" */ '../views/manager/MonetisationIndex')
	},
	{
		path: '/metaverse/:id/manage/referrals',
		name: 'Referrals',
		component: () => import(/* webpackChunkName: "manager" */ '../views/manager/ReferralsIndex')
	},
	{
		path: '/metaverse/:id/manage/engagement',
		name: 'Engagement',
		component: () => import(/* webpackChunkName: "manager" */ '../views/manager/EngagementIndex')
	},
	{
		path: '/metaverse/:id/manifest',
		name: 'Metaverse Manifest',
		component: () => import(/* webpackChunkName: "manifest" */ '../views/editor/ManifestEditor.vue')
	},
	{
		path: '/profile/dashboard',
		name: 'Profile Dashboard',
		component: () => import(/* webpackChunkName: "profile" */ '../views/profile/UserDashboard.vue')
	},
	{
		path: '/profile/land',
		name: 'Owned Land',
		component: () => import(/* webpackChunkName: "profile" */ '../views/profile/OwnedLand.vue')
	},
	{
		path: '/profile/update',
		name: 'Player Profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileUpdate.vue'),
	},
	{
		path: '/profile/settings',
		name: 'Game Settings',
		component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileGameSettings')
	},
	{
		path: '/profile/referrals',
		name: 'Profile Referrals',
		component: () => import(/* webpackChunkName: "profile" */ '../views/referral/ReferralProgress')
	},
	{
		path: '/estate',
		name: 'Estate Manager',
		component: () => import(/* webpackChunkName: "estate" */ '../views/estate/EstateContainer')
	},
	{
		path: '/land',
		name: 'Land Index',
		component: () => import(/* webpackChunkName: "estate" */ '../views/land/LandListIndex')
	},
	{
		path: '/land/:mint',
		name: 'Land Mint Show',
		component: () => import(/* webpackChunkName: "estate" */ '../views/land/LandShow')
	},
	{
		path: '/land/:mint/create',
		name: 'Land Build',
		component: () => import(/* webpackChunkName: "estate" */ '../views/land/LandConstruction')
	},
	{
		//TODO Remove
		path: '/map/land/:x/:y',
		name: 'Land Show',
		component: () => import(/* webpackChunkName: "estate" */ '../views/land/LandShow')
	},
	{
		path: '/buildings',
		name: 'Buildings',
		component: () => import(/* webpackChunkName: "estate" */ '../views/buildings/BuildingIndex')
	},
	{
		path: '/buildings/create',
		name: 'New Building',
		component: () => import(/* webpackChunkName: "estate" */ '../views/buildings/BuildingCreate')
	},
	{
		path: '/buildings/:id',
		name: 'Show Building',
		component: () => import(/* webpackChunkName: "estate" */ '../views/buildings/BuildingShow')
	},
	{
		path: '/buildings/:id/edit',
		name: 'Update Building',
		component: () => import(/* webpackChunkName: "estate" */ '../views/buildings/BuildingCreate')
	},
	{
		path: '/buildings/:id/hosts',
		name: 'Building Hosts',
		component: () => import(/* webpackChunkName: "estate" */ '../views/buildings/BuildingHosts')
	},
	{
		path: '/buildings/:id/oasis/host',
		name: 'Building Oasis Host',
		component: () => import(/* webpackChunkName: "estate" */ '../views/buildings/BuildingHostRequest')
	},
	{
		path: '/admin/prefabs/upload',
		name: 'Prefab Uploader',
		component: () => import(/* webpackChunkName: "admin" */ '../views/admin/PrefabUpload.vue')
	},
	{
		path: '/map',
		name: 'Map',
		component: () => import(/* webpackChunkName: "land_index" */ '../views/land/LandIndex2')
	},
	{
		path: '/map/sector/:id',
		name: 'Sector Show',
		component: () => import(/* webpackChunkName: "land_index" */ '../views/land/LandIndex2')
	},
	{
		path: '/privacy',
		name: 'Privacy Policy',
		component: () => import(/* webpackChunkName: "terms" */ '../views/info/PrivacyPolicy.vue')
	},
	{
		path: '/terms-and-conditions',
		name: 'Terms and Conditions',
		component: () => import(/* webpackChunkName: "terms" */ '../views/info/TermsConditions.vue')
	},
	{
		path: "*",
		// path: "/:catchAll(.*)",
		redirect: "/"
	}
]

const router = new VueRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	routes
})

export default router
