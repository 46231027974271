import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueToastr from "vue-toastr";

import "@fortawesome/fontawesome-free/js/all"
import "./assets/css/wallet_adapter.css";
import "./assets/css/theme.css";
import "./assets/css/style.css";
import "./assets/css/touch_controls.css";

import PortalVue from 'portal-vue'
Vue.use(PortalVue)


Vue.config.productionTip = false


Vue.use(VueToastr, {
	defaultProgressBar: false,
	defaultPosition: "toast-top-right"
});

const noAuth = {
	"/": true,
	"/signup": true,
	"/login": true,
	"/web2/login": true,
	"/web2/signup": true,
	"/qr/login": true,
	"/qr/signup": true,
	"/qr/tp": true,
	"/map": true,
	"/privacy": true,
	"/terms-and-conditions": true,
}


router.beforeEach((to, from, next) => {
	document.querySelector("html").style.background = "#003e79"
	if (!noAuth[to.path] && !store.state.wallet.connected) {

		let loginType= ''
		if (to.query.lt)
			loginType = `/${to.query.lt}`

		let path = `${loginType}/login?path=${encodeURIComponent(to.path)}`

		//Add other query strings
		if (!to.query[path]) {
			const keys = Object.keys(to.query)
			for (let i = 0; i < keys.length; i++) {
				path += `${i === 0 ? "?" : '&'}${keys[i]}=${to.query[keys[i]]}`
			}
		}

		if (from.fullPath === path)
			return

		router.push(path)
		// next({name: 'Login'})
	} else next()
})


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
