
export default {
	namespaced: true,
	state: () => ({
		isWeb3: true,
		jwt_token: "",
		connected: false,
		address: null,
	}),
	mutations: {
		set_wallet_connected(state, connected) {
			state.connected = connected;
		},

		set_wallet_address(state, addr) {
			state.isWeb3 = true
			state.address = addr;
		},

		set_web2_wallet(state, addr) {
			state.isWeb3 = false
			state.address = addr
		},

		clear_wallet(state, x = false) {
			localStorage.removeItem("jwt_token")
			state.isWeb3 = false
			state.connected = false
			state.address = ""
			state.jwt_token = ""
		},

		set_jwt_token(state, token) {
			state.isWeb3 = false
			state.jwt_token = token

			const base64Url = token.split('.')[1];
			const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));

			const claims = JSON.parse(jsonPayload)
			if (claims.wallet_addr) {
				state.address = claims.wallet_addr
				localStorage.setItem("jwt_token", token)
			}
		}
	}
}
