import User from '@/js/api/user'

export default {
	namespaced: true,
	state: () => ({
		userAssets: null,
		data: {
			name: "Guest"
		},
	}),
	mutations: {
		set_profile(state, profile) {
			state.data = profile;
		},
		set_user_assets(state, userAssets) {
			state.userAssets = userAssets
		}
	},
	actions: {
		async getProfile({commit}, wallet_addr) {
			const resp = await User.show(wallet_addr)
			const profile = resp.data

			commit('set_profile', profile)
		}
	}
}
