import Metaverse from "@/js/api/metaverse"
import MetaverseDemo from "@/js/api/metaverse_demo"
import Room from "@/js/api/room"
import User from "@/js/api/user"

export default {
	namespaced: true,
	state: () => ({
		loading: true,
		details: null,
		room: null,
		manifest: {
			actions: {},
			authentication: {
				allow_guests: true,
			},
			npcs: [
				{
					"id":"86de3991-1701-4668-8da1-e1cfa2f04e17",
					"name":"Rainbow Parrot",
					"character_id":"74687b35-d866-4488-bf5a-24dfdfba35a5",
					"health":-1,
					"level":-1,
					"walk_speed":10,
					"run_speed":13,
					"spawn_position":[0,0,-9.92],
					"rotation":[-3.141592653589793,-0.13473378177661322,-3.141592653589793],
					"scale":[0.1,0.1,0.1],
					"idle_animation":"idle",
					"run_animation":"run",
					"walk_animation":"walk",
					"interactions": {
						"click": {
							"action_type":"move_character_to",
							"data": {
								"position":[0,0,-23]
							}
						},
						"proximity": {
							"activation_radius":5,
							"action_type":"teleport_character_to",
							"data": {
								"position": [-11.9,0,-9.89]
							}
						}
					}
				},
			],
			characters: [],
			entities: [],
			environment: {},
			lighting: [],
			models: {},
			settings: {},
			sound: {},
			terrain: [],
			textures: {},
			voxels: [],
		},
	}),
	mutations: {
		metaverseLoaded(state) {
			state.loading = false
		},

		setMetaverseManifest(state, manifest) {
			state.manifest = {
				...state.manifest,
				...manifest,
			}
		},

		clearManifestDetails(state) {
			state.details = null
		},

		setMetaverseDetails(state, details) {
			state.details = details
		},

		setMetaverseRoom(state, room) {
			state.room = room
		},

		addManifestCharacter(state, character) {
			state.manifest.characters = [
				...state.manifest.characters,
				character,
			]
		},

		addManifestModel(state, model) {
			state.manifest.models = {
				...state.manifest.models,
				...model,
			}
		},

		add_entity(state, newEntity) {
			state.manifest.entities = [
				...state.manifest.entities,
				newEntity,
			]
		},
		updateEntityData(state, newData) {
			state.manifest.entities = state.manifest.entities.map(entity => {
				if (entity.id === newData.id) {
					return {
						...entity,
						data: {
							...newData.data,
							model: entity.data.model,
						}
					}
				}

				return entity
			})
		},
		update_entity(state, newEntity) {
			state.manifest.entities = state.manifest.entities.map(entity => {
				if (entity.id === newEntity.id) return {...entity, ...newEntity}

				return entity
			})
		},
		delete_entity(state, entity) {
			state.manifest.entities = state.manifest.entities.reduce((acc, all) => {
				if (all.id !== entity.id) return [...acc, all]
				
				return acc
			}, [])
		},

		add_terrain(state, newTerrainItem) {
			state.manifest.terrain = [
				...state.manifest.terrain,
				newTerrainItem,
			]
		},
		updateTerrainData(state, newData) {
			state.manifest.terrain = state.manifest.terrain.map(terrain => {
				if (terrain.id === newData.id) {
					return {
						...terrain,
						...newData,
						name: terrain.name,
					}
				}

				return terrain
			})
		},
		update_terrain(state, newTerrainItem) {
			state.manifest.terrain = state.manifest.terrain.map(terrain => {
				if (terrain.id === newTerrainItem.id) return {...terrain, ...newTerrainItem}

				return terrain
			})
		},
		delete_terrain(state, terrainItem) {
			state.manifest.terrain = state.manifest.terrain.reduce((acc, all) => {
				if (all.id !== terrainItem.id) return [...acc, all]
				
				return acc
			}, [])
		},

		update_environment(state, environment) {
			state.manifest.environment = {
				...state.manifest.environment,
				...environment,
			}
		},

		add_lighting(state, newLight) {
			state.manifest.lighting = [
				...state.manifest.lighting,
				newLight,
			]
		},
		update_lighting(state, newLight) {
			state.manifest.lighting = state.manifest.lighting.map(light => {
				if (light.id === newLight.id) return {...light, ...newLight}

				return light
			})
		},
		delete_lighting(state, light) {
			state.manifest.lighting = state.manifest.lighting.reduce((acc, all) => {
				if (all.id !== light.id) return [...acc, all]
				
				return acc
			}, [])
		},

		update_settings(state, settings) {
			state.manifest.settings = {
				...state.manifest.settings,
				...settings,
			}
		},

		add_model(state, newModel) {
			state.manifest.models = {
				...state.manifest.models,
				[newModel.id]: newModel.scene,
			}
		},
		update_model(state, newModel) {
			
			state.manifest.models = {
				...state.manifest.models,
				[newModel.id]: newModel.scene,
			}
		},
		delete_model(state, model) {
			const keys = Object.keys(state.manifest.models)

			state.manifest.models = keys.reduce((acc, all) => {
				if (all !== model.id) return {...acc, [all]: state.manifest.models[all]}

				return acc
			}, {})
		},

		add_character(state, newCharacter) {
			state.manifest.characters = [
				...state.manifest.characters,
				newCharacter,
			]
		},
		update_character(state, newCharacter) {
			state.manifest.characters = state.manifest.characters.map(character => {
				if (character.id === newCharacter.id) return {...character, ...newCharacter}

				return character
			})
		},
		delete_character(state, character) {
			state.manifest.characters = state.manifest.characters.reduce((acc, all) => {
				if (all.id !== character.id) return [...acc, all]
				
				return acc
			}, [])
		},

		add_npc(state, newNPC) {
			if (!state.manifest.npcs)
				state.manifest.npcs = []

			state.manifest.npcs = [
				...state.manifest.npcs,
				newNPC,
			]
		},
		update_npc(state, newNPC) {
			state.manifest.npcs = state.manifest.npcs.map(npc => {
				if (npc.id === newNPC.id) return {...npc, ...newNPC}

				return npc
			})
		},
		delete_npc(state, npc) {
			state.manifest.npcs = state.manifest.npcs.reduce((acc, all) => {
				if (all.id !== npc.id) return [...acc, all]
				
				return acc
			}, [])
		},

	},
	actions: {
		createMetaverse(_, payload) {
			return Metaverse.create(payload)
		},

		setManifest({ commit }, manifest) {
			commit('setMetaverseManifest', manifest)
		},

		clearManifestDetails({ commit }) {
			commit('clearManifestDetails')
		},

		async getMetaverseVersion({ commit, state }, version) {
			const otherMetaverse = (await Metaverse.version(state.details.uuid, version)).data

			commit('setMetaverseManifest', JSON.parse(atob(otherMetaverse)))
		},

		async updateMetaverse({ state }, signature) {
			await Metaverse.updateManifest(state.details.uuid, state.manifest, signature)
		},
		
		async getMetaverse({ commit }, metaverseID, ws_connection=true) {
			const metaverseDetails = (await Metaverse.show(metaverseID)).data

			commit('setMetaverseDetails', metaverseDetails)

			if (ws_connection) {
				const roomDetails = (await Room.wsDetail(metaverseDetails.target_oasis_id)).data
		
				commit('setMetaverseRoom', roomDetails)
			}

			const manifest = (await Metaverse.manifest(metaverseID, metaverseDetails.updated_at)).data

			commit('setMetaverseManifest', manifest)
			commit('metaverseLoaded')
		},

		async getDemoMetaverse({ commit }, demoMetaverseID, callManifest = true) {
			const demoMetaverseDetails = (await MetaverseDemo.show(demoMetaverseID)).data

			commit('setMetaverseDetails', demoMetaverseDetails)

			const roomDetails = (await Room.wsDetail(demoMetaverseDetails.target_oasis_id)).data

			commit('setMetaverseRoom', roomDetails)

			if (!callManifest)
				return

			const manifest = (await MetaverseDemo.manifest(demoMetaverseID, demoMetaverseDetails.updated_at)).data

			commit('setMetaverseManifest', manifest)
			commit('metaverseLoaded')
		},

		async initReadyMePlayerCharacter({ commit }, wallet_addr) {
			try {
				const profile = (await User.show(wallet_addr)).data

				commit('addManifestCharacter', {
					id: wallet_addr,
					name: wallet_addr,
					model: wallet_addr,
					scale: {
						x: 2,
						y: 2,
						z: 2,
					},
					device_type: '3D3RD',
					summary: '',
					animation_map: {},
				})

				commit('addManifestModel', {
					[wallet_addr]: profile.sprite_url,
				})

			} catch (e) {
				console.error(e)
			}
		},

		updateEntityData({ commit }, data) {
			commit('updateEntityData', data)
		},
		updateTerrainData({ commit }, data) {
			commit('updateTerrainData', data)
		},

		manifestAdd({ commit }, {property, object}) {
			commit(`add_${property}`, object)
		},

		manifestUpdate({ commit }, {property, object}) {
			commit(`update_${property}`, object)
		},

		manifestDelete({ commit }, {property, object}) {
			commit(`delete_${property}`, object)
		},
	},
}
