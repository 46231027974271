var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isMetaverseMobile,
          expression: "!isMetaverseMobile",
        },
      ],
      staticClass: "nav navbar-expand-lg navbar-dark bg-dark",
    },
    [
      _c("router-link", { staticClass: "navbar-brand", attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "d-inline-block align-top",
          attrs: {
            src: "assets/alpha_logo_new.png",
            height: _vm.isMobile ? "30px" : "44px",
            alt: "Hourglass",
          },
        }),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "collapse navbar-collapse",
          attrs: { id: "navbarSupportedContent" },
        },
        [
          _c("ul", { staticClass: "navbar-nav me-auto" }, [
            _c(
              "li",
              { staticClass: "nav-item mx-2 active" },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link", attrs: { to: "/", href: "#" } },
                  [
                    _c("i", { staticClass: "fa fa-home me-2" }),
                    _vm._v(" Home "),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("(current)"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item mx-2" },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link", attrs: { to: "/city" } },
                  [
                    _c("i", { staticClass: "fa fa-city me-2" }),
                    _vm._v(" City "),
                    _c("sup", { staticClass: "small text-primary" }, [
                      _vm._v("NEW!"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item mx-2" },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link", attrs: { to: "/map" } },
                  [
                    _c("i", { staticClass: "fa fa-globe me-2" }),
                    _vm._v(" World Map"),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item mx-2" },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link", attrs: { to: "/metaverse" } },
                  [
                    _c("i", { staticClass: "fa fa-search me-2" }),
                    _vm._v(" Explorer"),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "navbar-nav me-auto" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isMobile,
                    expression: "!isMobile",
                  },
                ],
                staticClass: "nav-item mx-2",
              },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link", attrs: { to: "/demo/editor" } },
                  [
                    _c("i", { staticClass: "fa fa-hammer me-2" }),
                    _vm._v(" Room Builder\n\t\t\t\t\t"),
                    _c("sup", { staticClass: "small text-primary" }, [
                      _vm._v("NEW!"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isConnected
            ? _c("ul", { staticClass: "navbar-nav ms-auto" }, [
                _c(
                  "li",
                  { staticClass: "nav-item mx-2" },
                  [_c("ReportBug", { staticClass: "nav-item white nolink" })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item mx-2" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-item white nolink",
                        attrs: { to: "/profile/dashboard", href: "#" },
                      },
                      [
                        _c("i", { staticClass: "fa fa-user me-2" }),
                        _vm._v(" Profile"),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "auth d-sm-block pb-2" },
            [
              !_vm.isConnected
                ? _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary me-3 px-4",
                      attrs: { to: "/login" },
                    },
                    [_vm._v("Login")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler py-0 fs-6",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item mx-2" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: {
            target: "_blank",
            href: "https://magiceden.io/marketplace/alphabatem",
          },
        },
        [_c("i", { staticClass: "fa fa-shop me-2" }), _vm._v(" Marketplace")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }